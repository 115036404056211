export default function input_label() {
    jQuery('input, textarea').focus(function () {
        jQuery(this).parents('.form-group').addClass('focused');
    });
    jQuery('input, textarea').blur(function () {
        var inputValue = jQuery(this).val();
        if (inputValue == "") {
            jQuery(this).removeClass('filled');
            jQuery(this).parents('.form-group').removeClass('focused');
        } else {
            jQuery(this).addClass('filled');
        }
    })
}