import '../styles/main.scss'
import 'bootstrap';
import marquee from './components/marquee'
import expandable from './components/expandable'
import hoverable from './components/hoverable'
import scrollUpHeader from "./components/scrollupHeader";
import input_label from './components/input_label'
import postsFilter from "./components/postsFilter";
import value_slider from "./components/value_slider";
import accordion from "./components/accordion";


document.addEventListener ('DOMContentLoaded', function() {
  marquee('.marquee') // Animated tickers
  expandable('.expandable')
  hoverable('.hoverable')
  scrollUpHeader('.sticky-header', 50, 'show')
  input_label()
  value_slider()
  accordion('.accordion')
  postsFilter('.posts-filters')


  var categoryElement = document.querySelector('.widget_wpc_filters_widget');
  var teemaButton = document.getElementById('teema');
  teemaButton.addEventListener('click', function () {
    categoryElement.classList.toggle('teema-active');
    teemaButton.classList.toggle('active');
  });

  var yearElement = document.querySelector('.widget_wpc_filters_widget');
  var aastaButton = document.getElementById('aasta');
  aastaButton.addEventListener('click', function () {
    yearElement.classList.toggle('aasta-active');
    aastaButton.classList.toggle('active');
  });

  var labelElement = document.querySelector('.widget_wpc_filters_widget');
  var labelButton = document.getElementById('label');
  labelButton.addEventListener('click', function() {
    labelElement.classList.toggle('label-active');
    labelButton.classList.toggle('active');
  });

  // show filter

  var filterElement = document.querySelector('.red-bg');
  var filterButton = document.getElementById('filtersearch');


  // Check if the URL contains #filter-open
  if (window.location.hash === '#filter-open') {
    filterElement.classList.add('filter-active');
    filterButton.classList.add('active');
  }


  filterButton.addEventListener('click', function () {
    filterElement.classList.toggle('filter-active');
    filterButton.classList.toggle('active');
  });



  // end filter btn
})
