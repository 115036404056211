export default function hoverable (selector = '.hoverable') {
  const items = document.querySelectorAll(selector)

  items.forEach((item) => {
    const transformer = item.querySelector('.hover-transformer')
    const hoverInfo = item.querySelector('.hover-info')
    const hoverInfoWidth = hoverInfo.offsetWidth

    if(window.innerWidth >= 992) transform(transformer, -hoverInfoWidth)

    item.addEventListener('mouseenter', () => {
      if(window.innerWidth < 992) {
        transform(transformer, 0)
        return
      }

      if(item.classList.contains('open')) {
        transform(transformer, -hoverInfoWidth)
      } else {
        transform(transformer, 0)
      }
    })

    item.addEventListener('mouseleave', () => {
      if(window.innerWidth < 992) {
        transform(transformer, 0)
        return
      }
      transform(transformer, -hoverInfoWidth)
    })
  })

  window.addEventListener('resize', () => {
    items.forEach((item) => {
      const transformer = item.querySelector('.hover-transformer')
      const hoverInfo = item.querySelector('.hover-info')
      const hoverInfoWidth = hoverInfo.offsetWidth
      
      if(window.innerWidth >= 992) {
        transform(transformer, -hoverInfoWidth)
      } else {
        transform(transformer, 0)
      }
    })
  })
}

function transform(el, pixels) {
  el.style.transform = 'translateX(' + pixels + 'px)'
}