export default function accordion() {
    (function(w) {
        w.addEventListener('load', function() {
            var accordionHeaders = document.querySelectorAll('.accordion-header');

            accordionHeaders.forEach(function(header) {
                header.addEventListener('click', function() {
                    var accordionItem = this.parentNode;
                    accordionItem.querySelector('.accordion-header').classList.toggle('active');
                    accordionItem.querySelector('.accordion-content').classList.toggle('active');
                });
            });

        });
    })(window);
}
