export default function marquee(querySelector = '.marquee', contentSelector = '.inner') {
  const marquees = document.querySelectorAll( querySelector )
  let speed = 1.5

  marquees.forEach( function (el) {
    const container = el.querySelector( contentSelector )
    const content = el.querySelector( contentSelector + ' > *' )

    // Make sure that marquee content covers all visible area + 1 text width
    const elWidth = content.offsetWidth
    const screenWidth = screen.width
    const widthRatio = Math.ceil(screenWidth / elWidth) // use screen width to eliminate need to recalculate responsive container width

    let clone = content.cloneNode( true )
    container.appendChild( clone )

    if(widthRatio > 2) {
      for (let i = 0; i <= widthRatio - 1; i++) {
        clone = content.cloneNode( true )
        container.appendChild( clone )
      }
    }

    let progress = 1

    function loop() {
      progress = (progress - speed) % (-elWidth)
      container.style.transform = 'translateX(' + progress + 'px)'

      window.requestAnimationFrame( loop )
    }
    loop()
  } )
}
