import expandable from "./expandable";
import hoverable from "./hoverable";


export default function postsFilter (containerSelector = '.posts-filters', postsContainerSelector = '.post-items', paginationSelector = '.pagination') {
  const container = document.querySelector(containerSelector);
  const itemsContainer = document.querySelector(postsContainerSelector);
  const pagingContainer = document.querySelector(paginationSelector);
  if (!container) {
    return;
  }

  let filterChoices = [...container.querySelectorAll('.filter-choice')];

  filterChoices.forEach(function(choice){
    choice.querySelector('.select-button')?.addEventListener('click', (ev) => {
      selectFilter(ev.target.closest('.filter-choice'))
      updateTabHeight();
    });

    choice.querySelector('.deselect-button')?.addEventListener('click', (ev) => {
      deselectFilter(ev.target.closest('.filter-choice'));
      updateTabHeight();
    });
  });

  addPaginationClickHandlers();
  goToPageFromURL();

  function addPaginationClickHandlers() {
    let pageLinks = [...pagingContainer.querySelectorAll('.page-numbers')];

    pageLinks.forEach(function (pageLink) {
      pageLink.addEventListener('click', (ev) => {
        ev.preventDefault(); // Prevent default link behavior

        if (pageLink.classList.contains('current')) return;

        let pageNumber = pageLink.dataset.page;
        updateView(pageNumber);
        updateURLParameter('page', pageNumber);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
    });
  }

  function goToPageFromURL() {
    let urlParams = new URLSearchParams(window.location.search);
    let page = urlParams.get('page');

    if (page) {
      updateView(page);
    }
  }

  function updateURLParameter(key, value) {
    let url = new URL(window.location);
    url.searchParams.set(key, value);
    history.pushState(null, '', url);
  }

  function updateView(page) {
    let pageLinks = [...pagingContainer.querySelectorAll('.page-numbers')];

    pageLinks.forEach((link) => {
      if (link.dataset.page === page) {
        link.click();
      }
    });
  }


  container.querySelector('.clear-services')?.addEventListener('click', () => {
    const selectedChoices = container.querySelectorAll('.selected-filters .filter-choice[data-filter-type="service"].selected')
    selectedChoices.forEach(choice => {
      deselectFilter(choice, false)
    })
    updateView()
    updateTabHeight();
  });
  container.querySelector('.clear-years')?.addEventListener('click', () => {
    const selectedChoices = container.querySelectorAll('.selected-filters .filter-choice[data-filter-type="year"].selected')
    selectedChoices.forEach(choice => {
      deselectFilter(choice, false)
    })
    updateView()
    updateTabHeight();
  });
  container.querySelector('.clear-all-filters')?.addEventListener('click', () => {
    const selectedChoices = container.querySelectorAll('.selected-filters .filter-choice.selected')
    selectedChoices.forEach(choice => {
      deselectFilter(choice, false)
    })
    updateView()
    updateTabHeight();
  });

  container.querySelector('#filter-search-input')?.addEventListener('keydown', (ev) => {
    if(ev.keyCode === 13) {
      const searchPhrase = ev.target.value
      ev.target.value = ''
      ev.target.blur()

      addSearchFilter(searchPhrase)
    }
  });

  function updateTabHeight(currentTab = container.querySelector('.filter-tab:not(.closed)')) {
    if(!currentTab) {
      return
    }
    const tabContainer = container.querySelector('.choices')

    tabContainer.style.height = currentTab.classList.contains('closed') ? 0 : currentTab.offsetHeight + 'px';
  }

  const toggleButtons = [...container.querySelectorAll('.tab-toggle')]

  toggleButtons.forEach((toggleButton) => {
    toggleButton.addEventListener('click', (ev) => {
      const property = toggleButton.dataset.filterProperty
      const currentTab = container.querySelector('.filter-tab[data-filter-property-tab="' + property + '"]')
      const isClosed = currentTab.classList.contains('closed');

      [...container.querySelectorAll('.filter-tab')].forEach((tab) => {
        tab.classList.add('closed')
      });
      if (!isClosed) {
        currentTab.classList.add('closed')
      } else {
        currentTab.classList.remove('closed')
      }

      toggleButtons.forEach((button) => {
        button.classList.remove('open')
      });

      if (!isClosed) {
        toggleButton.classList.remove('open')
      } else {
        toggleButton.classList.add('open')
      }
      updateTabHeight(currentTab);
    })
  })

  function addSearchFilter (searchPhrase, initial = false) {
    // add choice to selected-choices
    const selectedChoicesContainer = container.querySelector('.selected-filters')
    const template = container.querySelector('.search-choice-template .filter-choice')
    const clone = template.cloneNode(true)
    clone.dataset.filterPhrase = searchPhrase

    clone.querySelector('.select-button').innerText += searchPhrase

    selectedChoicesContainer.insertBefore(clone, selectedChoicesContainer.querySelector('.clear-all-filters'))

    clone.querySelector('.deselect-button').addEventListener('click', (ev) => {
      deselectFilter(ev.target.closest('.filter-choice'))
    })

    toggleClearFilterButtons('search')

    // update URL f parameter and history
    if(!initial) updateView()

    // query and print new grid
  }

  function selectFilter (choice, initial = false) {
    if(choice.classList.contains('selected')) return

    choice.classList.add('selected')

    // add choice to selected-choices
    const clone = choice.cloneNode(true)
    const selectedChoicesContainer = container.querySelector('.selected-filters')

    selectedChoicesContainer.insertBefore(clone, selectedChoicesContainer.querySelector('.clear-all-filters'))

    clone.querySelector('.deselect-button').addEventListener('click', (ev) => {
      deselectFilter(ev.target.closest('.filter-choice'))
    })

    toggleClearFilterButtons(choice.dataset.filterType)

    // update URL f parameter and history
    if(!initial) updateView()

    // query and print new grid
  }

  function deselectFilter (choice, doUpdateView = true) {
    // Deselect choice in filter controls
    if (choice.dataset.filterType === 'service') {
      const filterChoice = container.querySelector('.filter-controls .filter-choice[data-filter-id="' + choice.dataset.filterId + '"]')
      filterChoice.classList.remove('selected')
    } else if (choice.dataset.filterType === 'year') {
      const filterChoice = container.querySelector('.filter-controls .filter-choice[data-filter-year="' + choice.dataset.filterYear + '"]')
      filterChoice.classList.remove('selected')
    }

    // Remove choice from selected filters
    let selectedFilter;
    if (choice.dataset.filterType === 'service') {
      selectedFilter = container.querySelector('.selected-filters .filter-choice[data-filter-id="' + choice.dataset.filterId + '"]')
    } else if (choice.dataset.filterType === 'year') {
      selectedFilter = container.querySelector('.selected-filters .filter-choice[data-filter-year="' + choice.dataset.filterYear + '"]')
    } else if (choice.dataset.filterType === 'search') {
      selectedFilter = container.querySelector('.selected-filters .filter-choice[data-filter-phrase="' + choice.dataset.filterPhrase + '"]')
    }
    if (selectedFilter) selectedFilter.remove()

    toggleClearFilterButtons(choice.dataset.filterType)

    // update URL f parameter and history
    if(doUpdateView) updateView()

    // query and print new grid
  }

  function toggleClearFilterButtons (choiceType) {
    if (choiceType === 'service') {
      const selectedServiceFilters = [...container.querySelectorAll('.selected-filters .filter-choice[data-filter-type="service"].selected')]
      if (selectedServiceFilters.length > 0) {
        container.querySelector('.filter-controls .clear-services').classList.remove('hide')
      } else {
        container.querySelector('.filter-controls .clear-services').classList.add('hide')
      }
    } else if (choiceType === 'year') {
      const selectedServiceFilters = [...container.querySelectorAll('.selected-filters .filter-choice[data-filter-type="year"].selected')]
      if (selectedServiceFilters.length > 0) {
        container.querySelector('.filter-controls .clear-years').classList.remove('hide')
      } else {
        container.querySelector('.filter-controls .clear-years').classList.add('hide')
      }
    }

    const allSelectedServiceFilters = [...container.querySelectorAll('.selected-filters .filter-choice.selected')]
    if (allSelectedServiceFilters.length > 0) {
      container.querySelector('.selected-filters .clear-all-filters').classList.remove('hide')
    } else {
      container.querySelector('.selected-filters .clear-all-filters').classList.add('hide')
    }
  }

  function updateView (newPage) {
    const filters = parseChoices(newPage)
    //console.log('Filters:', filters);
    const filterString = btoa((JSON.stringify(filters)))
    // update URL locally
    const queryString = (filters.services.length !== 0 || filters.years.length !== 0 || filters.searches.length !== 0) ? '?f=' + filterString : ''
    history.pushState(null, null, window.location.origin + window.location.pathname + queryString);
    const lang = document.documentElement.lang === 'en-US' ? '/en' : ''
    const postType = container.dataset.filterPostType

    // query new grid
    fetch(window.location.origin + lang + '/wp-json/whatif/v1/filtered-posts?f=' + filterString + '&post_type=' + postType)
      .then((response) => response.json())
      .then((data) => {
        try{
          // const html = new DOMParser().parseFromString(htmlString, "text/html").body.innerHTML
          itemsContainer.innerHTML = data.html
          pagingContainer.innerHTML = data.pagination
          addPaginationClickHandlers()

          expandable('.expandable')
          hoverable('.hoverable')

          //console.log('is clicked')

          // animate to section start
          container.scrollIntoView(true)
        } catch (e) {
          console.log(e)
        }
        // itemsContainer.append(html)
      });
    console.log('URL:', window.location.href);

  }

  function parseChoices (currentPage) {
    const selectedFilters = [...container.querySelectorAll('.selected-filters .filter-choice.selected')];
    if (!currentPage) currentPage = (pagingContainer.querySelector('.page-numbers.current'))?.dataset.page;

    const filters = {
      services: [],
      years: [],
      searches: [],
      page: currentPage,
    }

    const taxonomy = container.dataset.filterTaxonomy
    const term = container.dataset.filterTerm

    if (taxonomy && term) {
      filters.taxonomy = {
        taxonomy_slug: taxonomy,
        term_slug: term,
      }
    }

    selectedFilters.forEach(filter => {
      if (filter.dataset.filterType === 'service') {
        filters.services.push(filter.dataset.filterId)
      } else if (filter.dataset.filterType === 'year') {
        filters.years.push(filter.dataset.filterYear)
      } else if (filter.dataset.filterType === 'search') {
        filters.searches.push(filter.dataset.filterPhrase)
      }
    })
    //console.log(filters)

    return filters
  }

  // Initialize selections based on url
  loadFiltersFromURL()

  function loadFiltersFromURL() {
    const urlParams = new URLSearchParams(window.location.search)
    // console.log(urlParams.get('f'))
    const filters = urlParams.get('f') ? JSON.parse(atob(urlParams.get('f'))) : false
    // console.log(filters)
    filters?.services?.forEach((service) => {
      const choice = container.querySelector('.filter-controls .filter-choice[data-filter-id="' + service +'"]')
      selectFilter(choice, true)
    })

    filters?.years?.forEach((year) => {
      const choice = container.querySelector('.filter-controls .filter-choice[data-filter-year="' + year +'"]')
      selectFilter(choice, true)
    })

    filters?.searches?.forEach((phrase) => {
      addSearchFilter(phrase, true)
    })
  }

  window.addEventListener('resize', () => {
    updateTabHeight()
  })
}