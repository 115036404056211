export default function expandable (selector = '.expandable') {
  const items = document.querySelectorAll(selector)

  items.forEach((item) => {
    const expandingContent = item.querySelector('.expandable-content')
    const expandingContainer = item.querySelector('.expandable-container')
    const hoverInfo = item.querySelector('.hover-info')

    item._contentHeight = expandingContent.offsetHeight
    expandingContent.style.height = '0'

    item.addEventListener('click', () => {
      if(item.classList.contains('open')) {
        // Close item
        item.classList.remove('open')
        expandingContent.style.height = '0'
        expandingContainer.style.width = '100%'
      } else {
        // Open item
        item.classList.add('open')
        expandingContent.style.height = item._contentHeight + 24 + 'px'
        expandingContainer.style.width = 'calc(100% - ' + hoverInfo.offsetWidth + 'px)'
      }
    })
  })

  window.addEventListener('resize', () => {
    items.forEach((item) => {
      const expandingContent = item.querySelector('.expandable-content')
      const expandingContainer = item.querySelector('.expandable-container')

      expandingContent.style.height = 'unset'
      item._contentHeight = expandingContent.offsetHeight
      expandingContent.style.height = '0'
      item.classList.remove('open')
      expandingContainer.style.width = '100%'
    })
  })
}