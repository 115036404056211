export default function value_slider() {
    (function(w) {
        w.addEventListener('load', function() {
            const [buttons, content, items] = [
                document.querySelectorAll('.value_tab'),
                document.getElementById('slider_child'),
                document.querySelectorAll('.value_item')
            ];

            // Set the first button active and the first item fully opaque
            buttons[0].classList.add('active');
            items[0].style.opacity = 1;

            let previousWidth = 0;
            let previousButton = buttons[0];

            buttons.forEach(function(button, index) {
                button.addEventListener('click', function() {
                    if (previousButton !== button) {
                        // Remove active class from the previous button and add it to the current button
                        previousButton.classList.remove('active');
                        button.classList.add('active');
                        previousButton = button;

                        // Set all items opacity to 0.5 and the selected item opacity to 1
                        items.forEach(function(item) {
                            item.style.opacity = 0.24;
                            item.classList.remove('active');
                        });
                        const selectedItem = document.getElementById(this.dataset.scroll);
                        selectedItem.style.opacity = 1;
                        selectedItem.classList.add('active');

                        // Calculate the scrollLeft value and apply the transform to the slider content
                        previousWidth = 0;
                        for (let i = 0; i < index; i++) {
                            previousWidth += buttons[i].offsetWidth;
                        }
                        const item = document.getElementById(this.dataset.scroll);
                        const itemOffsetLeft = item.offsetLeft;
                        let scrollLeft = itemOffsetLeft - button.offsetLeft;
                        if (button.dataset.scroll === "item1") {
                            scrollLeft = 0;
                        }
                        content.style.transform = `translateX(-${scrollLeft}px)`;
                    }
                });
            });
        });
    })(window);
}
