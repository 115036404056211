export default function scrollUpHeader(querySelector, threshold, showClass = 'show') {
  const header = document.querySelector( querySelector );
  let stillGoingUp = false
  let lastY = 0
  let lastDirectionChange = 0

  const scrollHandler = () => {
    const scrollTop = window.scrollY
    const currentlyGoingUp = (scrollTop - lastY) < 0

    if(scrollTop < window.innerHeight * 2) { //header.offsetHeight
      header.classList.remove('show')
    } else {
      if(currentlyGoingUp !== stillGoingUp) {
        lastDirectionChange = scrollTop
        stillGoingUp = currentlyGoingUp
      } else {
        if(scrollTop - lastDirectionChange > threshold) {
          header.classList.remove('show')
        } else if(scrollTop - lastDirectionChange < -threshold) {
          header.classList.add('show')
        }
      }
    }

    lastY = scrollTop
  }

  window.addEventListener('scroll', scrollHandler)
}
